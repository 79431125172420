import React, { useState } from 'react';
import IconButton from 'components/common/IconButton';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PropTypes from 'prop-types';
import {  Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SkillMatrixTableHeader = ({ handleChange }) => {
  const [keyword, setKeyword] = useState("");

  return (
    <div>
      <FalconCardHeader
        title=""
        light
        titleTag="h6"
        endEl={
          <div id="orders-actions" className="d-flex align-items-center">
            <Form.Control
              type="text"
              name="keyword"
              placeholder="Keyword"
              onChange={e => {
                setKeyword(e.target.value);
              }}
            />
            <IconButton color="primary" className="ms-2" onClick={() => handleChange("", keyword)}>
              <FontAwesomeIcon icon={faSearch} />
            </IconButton>
          </div>
        }
      />
    </div>
  );
};

SkillMatrixTableHeader.propTypes = {
  toggelFilter: PropTypes.func,
  handleChange:  PropTypes.func,
  meetingId: PropTypes.string
};

export default SkillMatrixTableHeader;
