import React from 'react';
import PropTypes from 'prop-types';
import BaseDropdownV2 from './BaseDropdownV2';
import { search } from 'services/skill-matric-services/smSkillCriteriaService';

const SmSkillCriteriaDropdown = ({
  fieldName="skillCriteria",
  title="Skill Criteria",
  closeMenuOnSelect=true,
  isMulti=false,
  placeholder="Skill Criteria",
  param,
  handleChange,
  selectedOptions,
  isDisabled = false
}) => {
  return (
    <>
      <BaseDropdownV2
        disabled={isDisabled}
        fieldName={fieldName}
        title={title}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        placeholder={placeholder}
        handleChange={handleChange}
        getSource={search}
        sourceParam={param}
        mapToOption={res => {
          return res?.items.map(i => {
            return {
              ...i,
              value: i.skillActivityId + '_' + i.skillLevelId,
              label: i.description
            };
          });
        }}
        value={selectedOptions}
      />
    </>
  );
};

SmSkillCriteriaDropdown.propTypes = {
  collapseValues: PropTypes.bool,
  param: PropTypes.bool,
  handleChange: PropTypes.func,
  selectedOptions: PropTypes.object,
  isDisabled: PropTypes.bool,
  maxToShow: PropTypes.number,
  title: PropTypes.string,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string,
  closeMenuOnSelect: PropTypes.func,
};

export default SmSkillCriteriaDropdown;
