import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import AppContext from 'context/Context';
import { post, patch } from 'services/skill-matric-services/smSubSkillService';
import SmAreaDropdown from 'components/Dropdowns/SkillMatrix/SmAreaDropdown';
import SmSkillLevelDropdown from 'components/Dropdowns/SkillMatrix/SmSkillLevelDropdown';
import SmSkillActivityDropdown from 'components/Dropdowns/SkillMatrix/SmSkillActivityDropdown';

const SkillLevelModal = ({ data, isShow, handleClose, handleSubmit }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const [formData, setFormData] = useState(data);

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    console.log(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdown = (field, value) => {
    console.log(field, value);
    const rs = { ...formData, [field]: value.id };
    console.log(rs);
    setFormData({ ...formData, [field]: value.id });
  };

  const onSubmit = async e => {
    console.log('submit');
    e.preventDefault();
    console.log(formData);
    if (formData.id > 0) {
      await patch(formData.id, { ...formData });
    } else {
      await post({ id: uuid(), ...formData });
    }
    handleSubmit();
  };
  return (
    <Modal show={isShow} onHide={handleClose} contentClassName="border">
      <Form onSubmit={onSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">
            {formData?.id > 0 ? 'Edit' : 'Create'} Sub Skill
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          
          <Form.Group className="mb-3" controlId="area">
            <Form.Label className="fs-0 required">Area</Form.Label>
            <SmAreaDropdown
              fieldName="areaId"
              isDisabled={formData?.id > 0}
              isMulti={false}
              selectedOptions={formData?.areaId}
              handleChange={handleDropdown}
              placeholder="Area"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="level">
            <Form.Label className="fs-0 required">Level</Form.Label>
            <SmSkillLevelDropdown
              required
              isDisabled={formData?.id > 0}
              selectedOptions={formData?.skillLevelId}
              handleChange={handleDropdown}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="activity">
            <Form.Label className="fs-0 required">Activity</Form.Label>
            <SmSkillActivityDropdown
              isDisabled={formData?.id > 0}
              selectedOptions={formData?.skillActivityId}
              handleChange={handleDropdown}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label className="fs-0 required">Name</Form.Label>
            <Form.Control
              type="text"
              name="skillName"
              required
              value={formData?.skillName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="Position">
            <Form.Label className="fs-0 required">Position</Form.Label>
            <Form.Control
              type="number"
              name="position"
              required
              value={formData?.position}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            variant="primary"
            type="submit"
            onClick={onsubmit}
            className="px-4 mx-0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SkillLevelModal.propTypes = {
  id: PropTypes.number,
  data: PropTypes.object,
  isShow: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default SkillLevelModal;
