import { URL } from '../../helpers/urlHelper';
import { baseSearch, basePost, baseGet } from 'services/base-services/baseService'

const baseURL = `${URL}/sm-skill-criterias`;
const search = async (request) => {
    return await baseSearch(baseURL, request);
}

const get = async (skillLevelId, skillActivityId) => {
    return await baseGet(baseURL, `${skillActivityId}/${skillLevelId}`);
}

const post = async (request) => {
    return await basePost(baseURL, request);
}

export {
    search,
    post,
    get
};

