import React from 'react';
import PropTypes from 'prop-types';
import BaseDropdownV2 from './BaseDropdownV2';
import { search } from 'services/skill-matric-services/smSkillActivityService';

const SmSkillActivityDropdown = ({
  param,
  handleChange,
  selectedOptions,
  isDisabled = false
}) => {
  return (
    <>
      <BaseDropdownV2
        disabled={isDisabled}
        fieldName="skillActivityId"
        title="skill Activity"
        closeMenuOnSelect={true}
        isMulti={false}
        placeholder="SkillActivity"
        handleChange={handleChange}
        getSource={search}
        sourceParam={param}
        mapToOption={res => {
          return res?.items.map(i => {
            return { ...i, value: i.id, label: i.ref + ' - ' + i.name };
          });
        }}
        value={selectedOptions}
      />
    </>
  );
};

SmSkillActivityDropdown.propTypes = {
  collapseValues: PropTypes.bool,
  maxToShow: PropTypes.number,
  param: PropTypes.object,
  handleChange: PropTypes.func,
  selectedOptions: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default SmSkillActivityDropdown;
