import React  from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

const MultiValue = ({ index, getValue, selectProps, ...props }) => {
  const maxToShow = selectProps.maxToShow;
  const values = getValue();

  if (!selectProps.collapseValues || values.length <= maxToShow) {
    return <components.MultiValue {...props} />;
  }

  if (index < maxToShow - 1) {
    return <components.MultiValue {...props} />;
  }

  if (index === maxToShow - 1) {
    return (
      <components.MultiValue {...props}>
        + {values.length - maxToShow + 1} others
      </components.MultiValue>
    );
  }

  return null;
};

MultiValue.propTypes = {
  index: PropTypes.number,
  getValue: PropTypes.func,
  options: PropTypes.array.isRequired,
  selectProps: PropTypes.object,
  placeholder: PropTypes.string,
  collapseValues: PropTypes.bool,
  maxToShow: PropTypes.number
};

const MultiSelectNoRef = ({
  options,
  placeholder,
  collapseValues = false,
  maxToShow = 4,
  disabled = false,
  ...rest
}) => {
  return (
    <Select
      closeMenuOnSelect={false}
      isMulti
      options={options}
      placeholder={placeholder}
      classNamePrefix="react-select"
      components={{ MultiValue }}
      collapseValues={collapseValues}
      maxToShow={maxToShow}
      isDisabled={disabled}
      {...rest}
    />
  );
};

MultiSelectNoRef.propTypes = {
  index: PropTypes.number,
  getValue: PropTypes.func,
  options: PropTypes.array.isRequired,
  selectProps: PropTypes.object,
  placeholder: PropTypes.string,
  collapseValues: PropTypes.bool,
  maxToShow: PropTypes.number,
  disabled: PropTypes.bool
};

export default MultiSelectNoRef;
