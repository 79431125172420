import PropTypes from 'prop-types';
import React, { useState , useRef, useEffect} from 'react';
import { splitStringMapValueToDropDown } from 'helpers/dropdownFuncHelper';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { getAllEvaUsers } from 'services/userServices';
const UsersInCellWidget = ({ usersText }) => {
  const [users, setUsers] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();
    getAllEvaUsers(controller.signal)
      .then(res => {
        if (!isMounted.current) return null;

        if (res?.length) setUsers(res);
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });
    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  const selectedUsers = splitStringMapValueToDropDown(usersText, users);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '1rem',
          maxWidth: '250px'
        }}
      >
        {selectedUsers.map(i => (
          <div key={i.login} style={{ display: 'flex' }}>
            <Link to="#" key={i.login}>
              <Flex
                alignItems="center"
                data-tooltip-id="my-tooltip"
                data-tooltip-html={i.name}
                data-tooltip-place="top"
              >
                {i.img ? (
                  <Avatar src={i.img} size="xl" className="me-2" />
                ) : (
                  <Avatar size="xl" name={i.name} className="me-2" />
                )}
                {/* <div className="flex-1">
                  <h5 className="mb-0 fs--1">{i.name}</h5>
                </div> */}
              </Flex>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

UsersInCellWidget.propTypes = {
  usersText: PropTypes.string,
  users: PropTypes.array
};

export default UsersInCellWidget;
