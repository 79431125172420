import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { splitStringMapValueToDropDown } from 'helpers/dropdownFuncHelper';
import { getDepartmentOtionList } from 'services/customer-service/scActionPlanService';
import UsersInCellWidget from 'components/common/users/UsersInCellWidget';
import moment from 'moment';

const WrapTrainingDetailLink = (id, content) => {
  return (
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/hr/skill-matrix/section/skills/core-training-record?id=` + id}
    >
      {content}
    </Link>
  );
};
const TrainingRecordTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  onPageSizeChange
}) => {
  const isMounted = useRef(null);
  const [dataDepartments, SetDataDepartments] = useState([]);
  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();

    const fetchDepart = async () => {
      const resDepart = await getDepartmentOtionList(controller.signal);
      if (resDepart) {
        SetDataDepartments(
          resDepart.map(x => {
            return {
              ...x,
              value: x.id,
              label: x.name
            };
          })
        );
      }
    };

    fetchDepart();
    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      isVisible: false
    },
    {
      accessor: 'trainee',
      Header: 'Trainee',
      Cell: rowData => {
        const { id, trainee } = rowData.row.original;
        return WrapTrainingDetailLink(
          id,
          <UsersInCellWidget usersText={trainee?.toString()} />
        );
      }
    },
    {
      accessor: 'sectionName',
      Header: 'Section',
      isVisible: true,
      Cell: rowData => {
        const { id, sectionName } = rowData.row.original;
        return WrapTrainingDetailLink(id, sectionName);
      }
    },
    {
      accessor: 'skillActivityName',
      Header: 'Skill Activity',
      isVisible: true,
      Cell: rowData => {
        const { id, skillActivityName } = rowData.row.original;
        return WrapTrainingDetailLink(id, skillActivityName);
      }
    },
    {
      accessor: 'skillLevelName',
      Header: 'Skill Level',
      isVisible: true,
      Cell: rowData => {
        const { id, skillLevelName } = rowData.row.original;
        return WrapTrainingDetailLink(id, skillLevelName);
      }
    },
    {
      accessor: 'areaName',
      Header: 'Area',
      isVisible: true,
      Cell: rowData => {
        const { id, areaName } = rowData.row.original;
        return WrapTrainingDetailLink(id, areaName);
      }
    },
    {
      accessor: 'comment',
      Header: 'Comment',
      isVisible: true,
      Cell: rowData => {
        const { id, comment } = rowData.row.original;
        return WrapTrainingDetailLink(id, comment);
      }
    },
    {
      accessor: 'department',
      Header: 'Department',
      isVisible: true,
      Cell: rowData => {
        const { department } = rowData.row.original;
        const data = splitStringMapValueToDropDown(
          department?.toString(),
          dataDepartments
        );
        return (
          <>
            {data.map(i => (
              <Link to="#" key={i.value}>
                <Flex alignItems="center">
                  <div className="flex-1">
                    <h5 className="mb-0 fs--1">{i.label}</h5>
                  </div>
                </Flex>
              </Link>
            ))}
          </>
        );
      }
    },
    {
      accessor: 'managerSignature',
      Header: 'Manager Signature',
      isVisible: false
    },
    {
      accessor: 'managerSignatureDate',
      Header: 'Manager Signature Date',
      isVisible: false
    },

    {
      accessor: 'startDate',
      Header: 'Start Date',
      isVisible: true,
      Cell: rowData => {
        const { startDate } = rowData.row.original;
        return moment(startDate).format('DD/MM/yyyy');
      }
    },
    {
      accessor: 'supervisorSignature',
      Header: 'Supervisor Signature',
      isVisible: false
    },
    {
      accessor: 'supervisorSignatureDate',
      Header: 'Supervisor Signature Date',
      isVisible: false
    },
    {
      accessor: 'traineeSignature',
      Header: 'Trainee Signature',
      isVisible: false
    },
    {
      accessor: 'traineeSignatureDate',
      Header: 'Trainee Signature Date',
      isVisible: false
    },
    {
      accessor: 'trainerSignature',
      Header: 'Trainer Signature',
      isVisible: false
    },
    {
      accessor: 'trainerSignatureDate',
      Header: 'Trainer Signature Date',
      isVisible: false
    },
    {
      accessor: 'trainingOfficer',
      Header: 'Training Officer',
      isVisible: false
    },
    {
      accessor: 'trainingOfficerDate',
      Header: 'Training Officer Date',
      isVisible: false
    }
  ];

  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        onPageSizeChange={onPageSizeChange}
        textAlign=""
      />
    </>
  );
};

TrainingRecordTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func
};

export default TrainingRecordTable;
