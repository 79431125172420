import React, { useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Col, Row } from 'react-bootstrap';
import { search } from 'services/skill-matric-services/smSkillCriteriaService';
import { search as searchActivities } from 'services/skill-matric-services/smSkillActivityService';
import { search as searchSkillLevel } from 'services/skill-matric-services/smSkillLevelService';
import SkillCriteriaModal from './SkillCriteriaModal';

const SkillCriteriaDashboard = () => {
  const [skillLevels, setSkillLevels] = useState([]);
  const [skillActivities, setSkillActivities] = useState([]);
  const [skillCriterias, setSkillCriterias] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [skillLevelId, setSkillLevelId] = useState(0);
  const [skillActivityId, setSkillActivityId] = useState(0);
  const [criteriaDes, setCriteriaDes] = useState(0);

  const setDataModal = (isModal, lvId, acId, des) => {
    setIsModal(isModal);
    setSkillLevelId(lvId);
    setSkillActivityId(acId);
    setCriteriaDes(des);
  };

  useEffect(() => {
    loadPage();
  }, []);

  const loadPage = () => {
    search({}).then(res => {
      setSkillCriterias(res.items);
    });

    searchActivities({}).then(res => {
      setSkillActivities(res.items);
    });
    searchSkillLevel({}).then(res => {
      setSkillLevels(res.items);
    });
  };
  const handleCloseModal = () => {
    setDataModal(false, 0, 0);
  };
  const handleSubmitModal = () => {
    setDataModal(false, 0, 0);
    loadPage();
  };

  const getData = (data, skillLevelId, skillActivityId) => {
    const item = data.find(
      d =>
        d.skillLevelId === skillLevelId && d.skillActivityId === skillActivityId
    );

    return item ? item.description : '';
  };
  return (
    <>
      <PageHeader
        title="Skill Criteria"
        description={`Manage and view all available Skill Criterias from this page.`}
        className="mb-3"
      ></PageHeader>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th
                  rowSpan="2"
                  style={{
                    border: '1px solid black',
                    padding: '10px',
                    backgroundColor: '#f2f2f2'
                  }}
                >
                  Ref
                </th>
                <th
                  rowSpan="2"
                  style={{
                    border: '1px solid black',
                    padding: '10px',
                    backgroundColor: '#f2f2f2'
                  }}
                >
                  Activity
                </th>
                {skillLevels?.map((lv, index) => (
                  <th
                    key={index}
                    colSpan="1"
                    style={{
                      border: '1px solid black',
                      padding: '10px',
                      backgroundColor: '#f2f2f2'
                    }}
                  >
                    {lv.levelNo}
                  </th>
                ))}
              </tr>
              <tr>
                {skillLevels?.map((lv, index) => (
                  <th
                    key={index}
                    colSpan="1"
                    style={{
                      border: '1px solid black',
                      padding: '10px',
                      backgroundColor: '#f2f2f2'
                    }}
                  >
                    {lv.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {skillActivities?.map((at, rowIndex) => (
                <tr key={rowIndex}>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '10px',
                      backgroundColor: '#f2f2f2'
                    }}
                  >
                    {at.ref}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '10px',
                      backgroundColor: '#f2f2f2'
                    }}
                  >
                    {at.name}
                  </td>
                  {skillLevels?.map((lv, colIndex) => (
                    <td
                      key={colIndex}
                      style={{ border: '1px solid black', padding: '10px' }}
                      onClick={() => setDataModal(true, lv.id, at.id, getData(skillCriterias, lv.id, at.id))}
                    >
                      {getData(skillCriterias, lv.id, at.id)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
      <SkillCriteriaModal
        isShow={isModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModal}
        skillActivityId={skillActivityId}
        skillLevelId={skillLevelId}
        criteriaDes={criteriaDes}
      />
    </>
  );
};
export default SkillCriteriaDashboard;
