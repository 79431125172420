import axios from 'axios';
import { BaseTableRS } from 'components/pages/stock-location/models/BaseTable';
import { objectToQueryString, getMessage400Request } from '../../helpers/utils'
import { ApiResponse } from 'helpers/stockLocationHelpers';

axios.defaults.withCredentials = true;

const baseSearch = async (baseUrl, request) => {
    const res = await axios({
        method: 'get',
        url: `${baseUrl}?${objectToQueryString(request)}`,
    })
        .catch((error) => {
            console.log('AXIOS ERROR', error)
            return new BaseTableRS([], 0);
        });

    if (res.status !== 200)
        return new BaseTableRS([], 0);

    return new BaseTableRS(res.data?.data?.items, res.data?.data?.total);
}

const basePost = async (baseURL, request) => {
    const result = new ApiResponse();
    if (!request) return result;
    const res = await axios({
        method: 'post',
        url: baseURL,
        data: { ...request },
        config: {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        },
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });
    if (res.status !== 200) {
        return result;
    }

    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

const baseGet = async (baseURL, id) => {
    const result = new ApiResponse();
    const res = await axios({
        method: 'get',
        url: `${baseURL}/${id}`,
    })
        .catch((res) => {
            console.log('ERROR: ', { ...res });
            result.message = getMessage400Request(res);
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

const basePatch = async (baseURL, dataRQ, id) => {
    const result = new ApiResponse();
    if (!dataRQ || id <= 0) return result;
    const res = await axios({
        method: 'patch',
        url: `${baseURL}/${id}`,
        data: dataRQ,
        config: {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        },
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

export {
    baseSearch,
    baseGet,
    basePost,
    basePatch,
};

