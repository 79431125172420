import { URL } from '../../helpers/urlHelper';
import { baseSearch, basePost, basePatch } from 'services/base-services/baseService'

const baseURL = `${URL}/sm-skill-activities`;
const search = async (request) => {
    return await baseSearch(baseURL, request);
}

const post = async (request) => {
    return await basePost(baseURL, request);
}
const patch = async (id, request) => {
    return await basePatch(baseURL, request, id);
  };

export {
    search,
    post,
    patch
};

