import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { getAllEvaUsers } from 'services/userServices';
import Avatar from 'components/common/Avatar';
const SectionTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  handleClickRow,
  onPageSizeChange
}) => {
  const [users, setUsers] = useState([]);
  const isMounted = useRef(null);
  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();

    getAllEvaUsers(controller.signal)
      .then(res => {
        if (!isMounted.current) return null;
        if (res?.length) {
          setUsers(res);
        }
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });

    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      isVisible: false
    },
    {
      accessor: 'name',
      Header: 'Name',
    },
    {
      accessor: 'supervisor',
      Header: 'Supervisor',
      Cell: rowData => {
        const { supervisor } = rowData.row.original;
        const selectedSupervisor = users.find(i => i.userId == supervisor)
        return (
          selectedSupervisor ? <>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '1rem',
                maxWidth: '250px'
              }}
            >
              <div key={selectedSupervisor?.login} style={{ display: 'flex' }}>
                <Link to="/e-commerce/customer-details" key={selectedSupervisor?.login}>
                  <Flex alignItems="center">
                    {selectedSupervisor?.img ? (
                      <Avatar src={selectedSupervisor?.img} size="xl" className="me-2" />
                    ) : (
                      <Avatar size="xl" name={selectedSupervisor?.login} className="me-2" />
                    )}
                    <div className="flex-1">
                      <h5 className="mb-0 fs--1">{selectedSupervisor?.login}</h5>
                    </div>
                  </Flex>
                </Link>
              </div>
            </div>
          </> : ""
        );
      }
    },
    {
      accessor: 'pointOfContact',
      Header: 'Point Of Contact',
    },
  ];

  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        onPageSizeChange={onPageSizeChange}
        handleClickRow={handleClickRow}
        textAlign=""
      />
    </>
  );
};

SectionTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  handleClickRow:  PropTypes.func,
};

export default SectionTable;
