import React from 'react';
import PageHeader from 'components/common/PageHeader';
import { Row } from 'react-bootstrap';
import RunningSkillMatrix from './RunningSkillMatrix';

const SkillMatrixDashboard = () => {
  return (
    <>
      <PageHeader
        title="Skill Matrix"
        description={`Manage and view all available Skill Matrix from this page.`}
        className="mb-3"
      ></PageHeader>
      <Row className="g-3 mb-3">
        <RunningSkillMatrix />
      </Row>
    </>
  );
};
export default SkillMatrixDashboard;
