import { URL } from '../../helpers/urlHelper';
import { baseSearch, basePost, basePatch, baseGet } from 'services/base-services/baseService';

const baseURL = `${URL}/sm-training-records`;
const search = async request => {
  return await baseSearch(baseURL, request);
};

const post = async (request) => {
  return await basePost(baseURL, request);
};

const get = async (id) => {
  return await baseGet(baseURL, id);
};


const patch = async (id, request) => {
  return await basePatch(baseURL, request, id);
};

export { search, post, patch, get };
