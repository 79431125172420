import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table, Spinner } from 'react-bootstrap';

const AdvanceTable = ({
  getTableProps,
  headers,
  headerGroups,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  tableRef,
  style,
  handleClickRow,
  loading = false
}) => {
  return (
    <div>
      <SimpleBarReact>
        <Table {...getTableProps(tableProps)} ref={tableRef} style={style}>
          <thead className={headerClassName}>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    style={{ position: 'fixed', top: 0 }}
                    key={i}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps(column.headerProps)
                    )}
                  >
                    {column.render('Header')}
                    {column.canSort ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="sort desc" />
                        ) : (
                          <span className="sort asc" />
                        )
                      ) : (
                        <span className="sort" />
                      )
                    ) : (
                      ''
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading && [
              <tr key="table-row-loading" className="text-center">
                <td colSpan={headers.length}>
                  <Spinner
                    variant="primary"
                    animation="border"
                    role="status"
                  ></Spinner>
                </td>
              </tr>
            ]}
            {page &&
              page.length > 0 &&
              !loading &&
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    key={i}
                    className={rowClassName}
                    // {...row.getRowProps()}
                    {...row.getRowProps({
                      onClick: () => handleClickRow(row)
                    })}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps(cell.column.cellProps)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {page &&
              !page.length &&
              !loading && [
                <tr key="table-row-empty" className="text-center">
                  <td colSpan={headers.length}>No Results</td>
                </tr>
              ]}
          </tbody>
        </Table>
      </SimpleBarReact>
    </div>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  loading: PropTypes.bool,
  headerGroups: PropTypes.object,
  style: PropTypes.object,
  handleClickRow: PropTypes.object,
  tableRef: PropTypes.object
};

export default AdvanceTable;
