import React from 'react';
import PropTypes from 'prop-types';
import BaseDropdownV2 from './BaseDropdownV2';
import { search } from 'services/skill-matric-services/smSkillLevelService';

const SmSkillLevelDropdown = ({ param, handleChange, selectedOptions, isDisabled=false }) => {
  return (
    <>
      <BaseDropdownV2
        disabled={isDisabled}
        fieldName="skillLevelId"
        title="Skill Level"
        closeMenuOnSelect={true}
        isMulti={false}
        placeholder="Skill Level"
        handleChange={handleChange}
        getSource={search}
        sourceParam={param}
        mapToOption={res => {
          return res?.items.map(i => {
            return { ...i,
          value: i.id,
          label: i.levelNo + ' - ' + i.name };
          });
        }}
        value={selectedOptions}
      />
    </>
  );
};

SmSkillLevelDropdown.propTypes = {
  collapseValues: PropTypes.bool,
  maxToShow: PropTypes.number,
  param: PropTypes.object,
  handleChange: PropTypes.func,
  selectedOptions: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default SmSkillLevelDropdown;
